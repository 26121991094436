import React from "react";
import { Helmet } from "react-helmet";
import BaseLayout from "components/layout/BaseLayout";

import { SectionBlock } from "components/section-box/SectionBlock";

// tslint:disable no-default-export
export default () => (
  <BaseLayout>
    <Helmet title="404" />

    <SectionBlock headingen="404" description="This is 404 page." />
  </BaseLayout>
);
